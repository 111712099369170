var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var kF,tF,qF,kfa;kF=function(a){if(null!=a&&null!=a.ia)a=a.cache;else{var b=kF[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=kF._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("Cached.-cache",a);}return a};
$CLJS.lF=function(a,b,c){if(null!=a&&$CLJS.Bc===a.Wa){var d=kF(a);var e=$CLJS.q(d);e=e.h?e.h(b):e.call(null,b);if($CLJS.n(e))return e;a=$CLJS.Oh.v(d,$CLJS.R,b,c.h?c.h(a):c.call(null,a));return a.h?a.h(b):a.call(null,b)}return c.h?c.h(a):c.call(null,a)};$CLJS.nF=function(a,b,c){a=$CLJS.mF.g(a,c);return a.h?a.h(b):a.call(null,b)};$CLJS.oF=function(a,b){return $CLJS.nF(a,b,null)};$CLJS.pF=function(a,b){$CLJS.Oh.v($CLJS.fC,$CLJS.cB,a,b)};$CLJS.rF=function(a,b){a=qF(a,b);return $CLJS.n(a)?a:$CLJS.Ej};
tF=function(){return $CLJS.eg.j(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zs,new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.A,$CLJS.rt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Hj);return $CLJS.zd(a)?["Invalid ",$CLJS.Mh.l($CLJS.H([$CLJS.A(a)]))," clause: ",$CLJS.Mh.l($CLJS.H([a]))].join(""):"not an MBQL clause"}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fp,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.pt,"not a known MBQL clause"],null),$CLJS.Ue(!1)],null)],null)],null),$CLJS.hf.h(function(a){return new $CLJS.P(null,
2,5,$CLJS.Q,[a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zh.g("mbql.clause",$CLJS.gh(a))],null)],null)}),$CLJS.q(sF))};$CLJS.wF=function(a,b){$CLJS.Se.v(uF,vF,a,b)};$CLJS.yF=function(a,b){$CLJS.Se.v(uF,xF,a,b)};$CLJS.Gp.prototype.ia=$CLJS.va(26,function(){return this.cache});$CLJS.Ip.prototype.ia=$CLJS.va(25,function(){return this.cache});$CLJS.Kp.prototype.ia=$CLJS.va(24,function(){return this.cache});$CLJS.Mp.prototype.ia=$CLJS.va(23,function(){return this.cache});
$CLJS.Op.prototype.ia=$CLJS.va(22,function(){return this.cache});$CLJS.Qp.prototype.ia=$CLJS.va(21,function(){return this.cache});$CLJS.Tp.prototype.ia=$CLJS.va(20,function(){return this.cache});$CLJS.Vp.prototype.ia=$CLJS.va(19,function(){return this.cache});$CLJS.Xp.prototype.ia=$CLJS.va(18,function(){return this.cache});$CLJS.Zp.prototype.ia=$CLJS.va(17,function(){return this.cache});$CLJS.aq.prototype.ia=$CLJS.va(16,function(){return this.cache});$CLJS.cq.prototype.ia=$CLJS.va(15,function(){return this.cache});
$CLJS.eq.prototype.ia=$CLJS.va(14,function(){return this.cache});$CLJS.gq.prototype.ia=$CLJS.va(13,function(){return this.cache});$CLJS.iq.prototype.ia=$CLJS.va(12,function(){return this.cache});$CLJS.kq.prototype.ia=$CLJS.va(11,function(){return this.cache});$CLJS.nq.prototype.ia=$CLJS.va(10,function(){return this.cache});$CLJS.sq.prototype.ia=$CLJS.va(9,function(){return this.cache});$CLJS.uq.prototype.ia=$CLJS.va(8,function(){return this.cache});$CLJS.xq.prototype.ia=$CLJS.va(7,function(){return this.cache});
$CLJS.Hq.prototype.ia=$CLJS.va(6,function(){return this.cache});$CLJS.YD.prototype.ia=$CLJS.va(5,function(){return this.cache});$CLJS.mF=function mF(a){switch(arguments.length){case 1:return mF.h(arguments[0]);case 2:return mF.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.mF.h=function(a){return $CLJS.mF.g(a,null)};$CLJS.mF.g=function(a,b){return $CLJS.lF($CLJS.Eo.g(a,b),$CLJS.Ik,$CLJS.Cn)};$CLJS.mF.A=2;
qF=function qF(a,b){return $CLJS.E.g(a,$CLJS.Ej)?null:$CLJS.E.g(b,$CLJS.Ej)?null:$CLJS.rC(a,b)?b:$CLJS.rC(b,a)?a:$CLJS.Re(function(d){return $CLJS.Re(function(e){return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[d,e],null),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))?null:qF.g?qF.g(d,e):qF.call(null,d,e)},$CLJS.ee(b,$CLJS.Yh(b)))},$CLJS.ee(a,$CLJS.Yh(a)))};$CLJS.zF=new $CLJS.M(null,"exp","exp",-261706262);$CLJS.AF=new $CLJS.M(null,"substring","substring",1140866276);
$CLJS.BF=new $CLJS.M(null,"dimension","dimension",543254198);$CLJS.CF=new $CLJS.M(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.DF=new $CLJS.M(null,"ceil","ceil",-1824929952);$CLJS.EF=new $CLJS.M(null,"query","query",-1288509510);$CLJS.FF=new $CLJS.M(null,"power","power",-937852079);$CLJS.GF=new $CLJS.M(null,"segment","segment",-964921196);$CLJS.HF=new $CLJS.M(null,"concat","concat",-2108183992);$CLJS.IF=new $CLJS.M(null,"abs","abs",-246026477);
$CLJS.JF=new $CLJS.M(null,"is-null","is-null",-1997050930);$CLJS.KF=new $CLJS.M(null,"between","between",1131099276);$CLJS.LF=new $CLJS.M(null,"is-empty","is-empty",-1040302908);$CLJS.MF=new $CLJS.M(null,"filter","filter",-948537934);$CLJS.NF=new $CLJS.M(null,"distinct","distinct",-1788879121);$CLJS.OF=new $CLJS.M(null,"var","var",-769682797);$CLJS.PF=new $CLJS.M(null,"next","next",-117701485);$CLJS.QF=new $CLJS.M(null,"inside","inside",1972503011);$CLJS.RF=new $CLJS.M(null,"case","case",1143702196);
$CLJS.SF=new $CLJS.M(null,"not-empty","not-empty",388922063);$CLJS.TF=new $CLJS.M(null,"percentile","percentile",-601188752);$CLJS.UF=new $CLJS.M(null,"not-null","not-null",-1326718535);$CLJS.VF=new $CLJS.M(null,"sqrt","sqrt",-1270051929);$CLJS.WF=new $CLJS.M(null,"breakout","breakout",-732419050);$CLJS.XF=new $CLJS.M(null,"aggregation","aggregation",1597476696);$CLJS.YF=new $CLJS.M(null,"desc","desc",2093485764);$CLJS.ZF=new $CLJS.M(null,"starts-with","starts-with",366503009);
$CLJS.$F=new $CLJS.M(null,"does-not-contain","does-not-contain",-274946853);$CLJS.aG=new $CLJS.M(null,"short","short",1928760516);$CLJS.bG=new $CLJS.M(null,"avg","avg",197406200);$CLJS.cG=new $CLJS.M(null,"metric","metric",408798077);$CLJS.dG=new $CLJS.M(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.eG=new $CLJS.M(null,"asc","asc",356854569);$CLJS.fG=new $CLJS.M(null,"floor","floor",1882041021);$CLJS.gG=new $CLJS.M(null,"contains","contains",676899812);
$CLJS.hG=new $CLJS.M(null,"field","field",-1302436500);$CLJS.iG=new $CLJS.M(null,"cum-count","cum-count",-1964431543);$CLJS.jG=new $CLJS.M(null,"regex-match-first","regex-match-first",-657299718);$CLJS.kG=new $CLJS.M(null,"coalesce","coalesce",654622029);$CLJS.lG=new $CLJS.M("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);$CLJS.mG=new $CLJS.M(null,"round","round",2009433328);$CLJS.nG=new $CLJS.M(null,"temporal-unit","temporal-unit",-1892306358);
$CLJS.oG=new $CLJS.M(null,"share","share",-589433933);$CLJS.pG=new $CLJS.M(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.qG=new $CLJS.M(null,"count-where","count-where",385407720);$CLJS.rG=new $CLJS.M(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.sG=new $CLJS.M(null,"ends-with","ends-with",-456791011);$CLJS.tG=new $CLJS.M(null,"time-interval","time-interval",704622015);$CLJS.uG=new $CLJS.M(null,"sum","sum",136986814);
$CLJS.vG=new $CLJS.M(null,"median","median",569566131);kfa=new $CLJS.M("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);$CLJS.xG=new $CLJS.M(null,"stddev","stddev",-865474939);$CLJS.yG=new $CLJS.M(null,"trim","trim",774319767);$CLJS.zG=new $CLJS.M(null,"!\x3d","!\x3d",-1841737356);$CLJS.AG=new $CLJS.M(null,"ltrim","ltrim",1654269283);$CLJS.BG=new $CLJS.M(null,"category","category",-593092832);
$CLJS.CG=new $CLJS.M(null,"sum-where","sum-where",2135348428);$CLJS.DG=new $CLJS.M(null,"/","/",1282502798);$CLJS.EG=new $CLJS.M(null,"cum-sum","cum-sum",2064902349);$CLJS.FG=new $CLJS.M(null,"log","log",-1595516004);$CLJS.GG=new $CLJS.M(null,"replace","replace",-786587770);$CLJS.HG=new $CLJS.M(null,"rtrim","rtrim",-661336449);var sF=$CLJS.Xe($CLJS.bh),xF;$CLJS.X($CLJS.NE,tF());$CLJS.gC(sF,kfa,function(){return $CLJS.X($CLJS.NE,tF())});
$CLJS.IG=function(){function a(d,e,f,k){c.g?c.g(d,k):c.call(null,d,k);$CLJS.LE.m(null,d,function(){return f});return null}function b(d,e){var f=$CLJS.zh.g("mbql.clause",$CLJS.gh(d));$CLJS.X(f,e);$CLJS.Id($CLJS.q(sF),d)||$CLJS.Oh.j(sF,$CLJS.be,d);return null}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
xF=function xF(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return xF.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
xF.l=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,$CLJS.eg.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Li,new $CLJS.h(null,1,[$CLJS.pt,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,a],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null)],null)],null)],null),b)],null)};xF.A=1;
xF.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};var vF=function vF(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return vF.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
vF.l=function(a,b){return $CLJS.eg.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.pt,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null)],null),b)};vF.A=1;vF.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var uF=function uF(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return uF.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};uF.l=function(a,b,c){c=$CLJS.E.g($CLJS.A(c),$CLJS.it)?$CLJS.ee($CLJS.hd(c),$CLJS.bf(2,c)):$CLJS.ee(null,c);var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);a=$CLJS.Se.j(a,b,d);return $CLJS.n(c)?$CLJS.IG.v(b,$CLJS.it,c,a):$CLJS.IG.g(b,a)};uF.A=2;
uF.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};