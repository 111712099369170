import {
  handleActions,
  combineReducers,
  createAction,
  createThunkAction,
} from "metabase/lib/redux";
import { MetabaseApi, RevisionsApi } from "metabase/services";
import {METABASE_SAMPLE_DATABASE} from "metabase/lib/cookies";

export const UPDATE_PREVIEW_SUMMARY =
  "metabase/admin/datamodel/UPDATE_PREVIEW_SUMMARY";

export const updatePreviewSummary = createAction(
  UPDATE_PREVIEW_SUMMARY,
  async query => {
    const result = await MetabaseApi.dataset(query);
    return result.data.rows[0][0];
  },
);

// REVISION HISTORY

export const FETCH_REVISIONS = "metabase/admin/datamodel/FETCH_REVISIONS";

export const fetchRevisions = createThunkAction(
  FETCH_REVISIONS,
  ({ entity, id }) =>
    async () =>
      RevisionsApi.get({ entity, id }),
);

export const refreshBiasAppLocalStorage = () => {
  localStorage.removeItem(METABASE_SAMPLE_DATABASE);
};

export const getShowDatabases = (databases, isSuperuser) => {
  if (isSuperuser) {
    localStorage.removeItem(METABASE_SAMPLE_DATABASE)
    return databases;
  }
  const biasDatabases = localStorage.getItem(METABASE_SAMPLE_DATABASE)
    ? localStorage.getItem(METABASE_SAMPLE_DATABASE).split(",")
    : [];
  const showDatabases = [];
  if (biasDatabases.length > 0) {
    databases.forEach(database => {
      if (biasDatabases.indexOf(database.id.toString()) !== -1) {
        showDatabases.push(database);
      }
    });
  }
  return showDatabases;
}

// reducers

const previewSummary = handleActions(
  { [UPDATE_PREVIEW_SUMMARY]: { next: (state, { payload }) => payload } },
  null,
);

const revisions = handleActions(
  { [FETCH_REVISIONS]: { next: (state, { payload }) => payload } },
  null,
);

export default combineReducers({ previewSummary, revisions });
