import cx from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";

import { PLUGIN_LOGO_ICON_COMPONENTS } from "metabase/plugins";

class DefaultLogoIcon extends Component {
  static defaultProps = {
    height: 32,
  };
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    dark: PropTypes.bool,
  };

  render() {
    const { dark, height, width } = this.props;
    return (
      <svg
        className={cx("Icon", {"text-brand": !dark}, {"text-white": dark})}
        viewBox="0 0 136 136"
        width={width}
        height={height}
        fill="currentcolor"
        data-testid="main-logo"
      >
        <path
          className="st0"
          style={{fill: "#82B9D3"}}
          d="M103.1,112.3L85.2,94.7c-2.9,1.3-6.1,1.7-9.3,1.2l2.7,28.5c0.2,1.8,1.8,3.2,3.7,3c0,0,0,0,0,0
	c7.8-0.9,15-4.2,20.7-9.6c1.5-1.5,1.6-3.9,0.2-5.4C103.1,112.4,103.1,112.3,103.1,112.3z"
        />
        <path
          className="st1"
          style={{fill: "#D36C67"}}
          d="M74.3,95.6c-2.5-0.7-4.9-2-6.8-3.7L46.1,117c-1.7,2-1.5,5,0.5,6.7c0.1,0.1,0.2,0.2,0.4,0.3c7.6,5.2,16.7,8,26,8
	l0,0c2.4,0,4.3-2,4.3-4.3c0-0.1,0-0.3,0-0.4L74.3,95.6z"
        />
        <path
          className="st2"
          style={{fill: "#EBC36B"}}
          d="M64.8,88.6c-1.2-1.8-1.7-4-1.5-6.2l-44.2-6c-2.3-0.3-4.4,1.3-4.7,3.6c0,0.1,0,0.2,0,0.3
	c-0.1,1.4-0.2,2.8-0.2,4.2c0,14.8,5.8,29,16.1,39.6c2.1,2.1,5.6,2.2,7.7,0.1c0.1-0.1,0.2-0.3,0.4-0.4l28-33.1
	C65.8,90,65.3,89.4,64.8,88.6z"
        />
        <path
          className="st3"
          style={{fill: "#9AC37D"}}
          d="M69.9,73.5L56,23.6c-1.1-4-5.2-6.3-9.1-5.2c-0.3,0.1-0.5,0.2-0.8,0.2C25.9,26.7,11,44.4,6.7,65.7
	C6,69.4,8.3,73,12,73.7c0.1,0,0.3,0.1,0.4,0.1l51.1,7.1C64.3,77.5,66.7,74.7,69.9,73.5z"
        />
        <path
          className="st4"
          style={{fill: "#556CB3"}}
          d="M131.3,37C115.5,16,89.6,5.2,63.6,8.7c-4.3,0.6-7.4,4.6-6.8,9c0,0.3,0.1,0.7,0.2,1L71.9,73
	c0.5-0.1,1-0.1,1.6-0.1c3.2,0,6.2,1.5,8.1,4l47.7-29.3c3.4-2.1,4.5-6.6,2.3-10C131.5,37.3,131.4,37.2,131.3,37z"
        />
        <path
          className="st5"
          style={{fill: "#E2855C"}}
          d="M87.2,93.6L86.7,94l11.3,11c1.4,1.3,3.6,1.3,4.9-0.1c0.2-0.2,0.3-0.4,0.5-0.6c2.1-3.3,3.6-7,4.4-10.9
	c0.4-1.9-0.8-3.7-2.6-4.1c0,0,0,0,0,0l-10.9-2.3C92.3,89.7,90,91.9,87.2,93.6"
        />
      </svg>
    );
  }
}

export default function LogoIcon(props) {
  const [Component = DefaultLogoIcon] = PLUGIN_LOGO_ICON_COMPONENTS;
  return <Component {...props} />;
}
