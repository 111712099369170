const loadJs = (src, token) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `${src}&AuthToken=${token}`;
    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject();
    };
    document.body.appendChild(script);
  });
};

// 获取元素的 XPath 路径
const getXPath = element => {
  if (element.id !== "") {
    // 如果元素具有 ID 属性
    return '//*[@id="' + element.id + '"]'; // 返回格式为 '//*[@id="elementId"]' 的 XPath 路径
  }
  if (element === document.body) {
    // 如果当前元素是 document.body
    return "/html/body"; // 返回 '/html/body' 的 XPath 路径
  }

  let index = 1;
  const childNodes = element.parentNode ? element.parentNode.childNodes : []; // 获取当前元素的父节点的子节点列表
  const siblings = childNodes;

  for (let i = 0; i < siblings.length; i++) {
    const sibling = siblings[i];
    if (sibling === element) {
      // 遍历到当前元素
      // 递归调用，获取父节点的 XPath 路径，然后拼接当前元素的标签名和索引
      return (
        getXPath(element.parentNode) +
        "/" +
        element.tagName.toLowerCase() +
        "[" +
        index +
        "]"
      );
    }
    if (sibling.nodeType === 1 && sibling.tagName === element.tagName) {
      // 遍历到具有相同标签名的元素
      index++; // 增加索引值
    }
  }
};

const parseMgs = json => {
  try {
    if (!json) {
      return undefined;
    }
    return JSON.parse(json);
  } catch (e) {
    return undefined;
  }
};

const fillValue = (htmlCode, object) => {
  Object.keys(object || {}).forEach(key => {
    htmlCode = htmlCode.replace(`$${key}$`, object[key]);
  });
  return htmlCode;
};

export { loadJs, getXPath, parseMgs, fillValue };
