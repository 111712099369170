/* eslint "react/prop-types": "warn" */
// zhangsk: init login page
import React, { Component } from "react";
import Cookies from "js-cookie";
import { Base64 } from "js-base64";
import PropTypes from "prop-types";
import LoadingSpinner from "metabase/components/LoadingSpinner";
import { SessionApi } from "metabase/services";
import {
  METABASE_SESSION_COOKIE,
  METABASE_SAMPLE_DATABASE,
} from "../lib/cookies";

export default class InitLogin extends Component {
  propTypes = {
    location: PropTypes.shape({
      query: PropTypes.shape({
        _t: PropTypes.string,
        show: PropTypes.string,
        caseId: PropTypes.string,
        briefcaseDomain: PropTypes.string,
        briefcaseToken: PropTypes.string,
      }),
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      seconds: 3,
      authInfo: JSON.parse(Base64.decode(this.props.location.query._t)),
      show: this.props.location.query.show,
      caseId: this.props.location.query.caseId,
      // eslint-disable-next-line react/prop-types
      briefcaseDomain: this.props.location.query.briefcaseDomain,
      briefcaseToken: this.props.location.query.briefcaseToken,
    };
  }

  componentDidMount() {
    this.removeCookieAndLocalStorage().finally(_ => {
      this.setCookieAndLocalStorage();
      this.initTimer();
    });
  }

  async removeCookieAndLocalStorage() {
    await SessionApi.delete();
    localStorage.removeItem(METABASE_SAMPLE_DATABASE);
  }

  setCookieAndLocalStorage() {
    const options = {
      path: window.MetabaseRoot || "/",
      expires: 365,
      secure: window.location.protocol === "https:",
    };
    localStorage.setItem(METABASE_SAMPLE_DATABASE, this.state.authInfo.dbs);
    Cookies.set(METABASE_SESSION_COOKIE, this.state.authInfo.token, options);
  }

  initTimer() {
    const timer = setInterval(() => {
      this.setState(
        preState => ({
          seconds: preState.seconds - 1,
        }),
        () => {
          if (this.state.seconds === 0) {
            clearInterval(timer);
          }
        },
      );
    }, 1000);
  }

  login() {
    if (this.state.show && this.state.caseId && this.state.briefcaseDomain) {
      localStorage.setItem(
        "briefcase_panel",
        `${this.state.briefcaseDomain}/api/panels/init?caseId=${this.state.caseId}`,
      );
      localStorage.setItem("briefcase_case_id", this.state.caseId);
    } else {
      localStorage.removeItem("briefcase_panel");
      localStorage.removeItem("briefcase_case_id");
    }

    if (
      this.state.authInfo.redirectUrl &&
      this.state.authInfo.redirectUrl !== ""
    ) {
      location.href = this.state.authInfo.redirectUrl;
      return;
    }

    location.href = "/browse";
  }

  render() {
    const style = {
      color: "rgb(80 158 227)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    };
    const margin = { marginLeft: "5px" };
    return (
      <div style={style}>
        <LoadingSpinner />
        <span style={margin}>{this.state.seconds}秒后跳转...</span>
        {this.state.seconds === 0 ? this.login() : false}
      </div>
    );
  }
}
