var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var PI,RI,TI,eJ,iJ,qJ;$CLJS.OI=new $CLJS.M(null,"get-month","get-month",-369374731);PI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.QI=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);RI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.SI=new $CLJS.M(null,"iso","iso",-1366207543);TI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.UI=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.VI=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.WI=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.XI=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.YI=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.ZI=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.$I=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.aJ=new $CLJS.M(null,"us","us",746429226);$CLJS.bJ=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.cJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.dJ=new $CLJS.M(null,"now","now",-1650525531);eJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.fJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.gJ=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.hJ=new $CLJS.M(null,"get-week","get-week",-888059349);
iJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.jJ=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.kJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.lJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.mJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.nJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.oJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.pJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);qJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.rJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.sJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.tJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.uJ=new $CLJS.M(null,"target","target",253001721);$CLJS.wF($CLJS.$I,$CLJS.H([$CLJS.it,$CLJS.cD,$CLJS.yi,$CLJS.cH]));$CLJS.LE.m(null,$CLJS.XI,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.ME(a);return $CLJS.vd(a)?(a=$CLJS.fu.g(a,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.SC,null,$CLJS.rD,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.A(a):a):a});$CLJS.pF($CLJS.XI,$CLJS.bF);
for(var vJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,$CLJS.pJ],null)),wJ=null,xJ=0,yJ=0;;)if(yJ<xJ){var zJ=wJ.X(null,yJ);$CLJS.wF(zJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null),$CLJS.yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cH],null)]));$CLJS.pF(zJ,$CLJS.XI);yJ+=1}else{var AJ=$CLJS.y(vJ);if(AJ){var BJ=AJ;if($CLJS.Ad(BJ)){var CJ=$CLJS.lc(BJ),Tga=$CLJS.mc(BJ),Uga=CJ,Vga=$CLJS.D(CJ);vJ=Tga;wJ=Uga;xJ=Vga}else{var DJ=$CLJS.A(BJ);$CLJS.wF(DJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.UE],null),$CLJS.yi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cH],null)]));$CLJS.pF(DJ,$CLJS.XI);vJ=$CLJS.B(BJ);wJ=null;xJ=0}yJ=0}else break}
for(var EJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.VI,$CLJS.OI,$CLJS.gJ,$CLJS.oJ,$CLJS.kJ,$CLJS.UI,$CLJS.rJ],null)),FJ=null,GJ=0,HJ=0;;)if(HJ<GJ){var Wga=FJ.X(null,HJ);$CLJS.wF(Wga,$CLJS.H([$CLJS.it,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)]));HJ+=1}else{var IJ=$CLJS.y(EJ);if(IJ){var JJ=IJ;if($CLJS.Ad(JJ)){var KJ=$CLJS.lc(JJ),Xga=$CLJS.mc(JJ),Yga=KJ,Zga=$CLJS.D(KJ);EJ=Xga;FJ=Yga;GJ=Zga}else{var $ga=$CLJS.A(JJ);$CLJS.wF($ga,$CLJS.H([$CLJS.it,
$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)]));EJ=$CLJS.B(JJ);FJ=null;GJ=0}HJ=0}else break}$CLJS.wF($CLJS.ZI,$CLJS.H([$CLJS.it,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$G],null)]));
for(var LJ=$CLJS.y(new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.rJ,null,$CLJS.kJ,null,$CLJS.oJ,null,$CLJS.lJ,null,$CLJS.UI,null,$CLJS.OI,null,$CLJS.VI,null,$CLJS.gJ,null],null),null)),MJ=null,NJ=0,OJ=0;;)if(OJ<NJ){var aha=MJ.X(null,OJ);$CLJS.wF(aha,$CLJS.H([$CLJS.it,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)]));OJ+=1}else{var PJ=$CLJS.y(LJ);if(PJ){var QJ=PJ;if($CLJS.Ad(QJ)){var RJ=$CLJS.lc(QJ),bha=$CLJS.mc(QJ),cha=RJ,dha=$CLJS.D(RJ);
LJ=bha;MJ=cha;NJ=dha}else{var eha=$CLJS.A(QJ);$CLJS.wF(eha,$CLJS.H([$CLJS.it,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)]));LJ=$CLJS.B(QJ);MJ=null;NJ=0}OJ=0}else break}$CLJS.X(qJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,$CLJS.SI,$CLJS.aJ,$CLJS.fJ],null));
$CLJS.yF($CLJS.hJ,$CLJS.H([$CLJS.it,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.mJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.rE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,2,[$CLJS.pt,"valid timezone ID",$CLJS.rt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Hj);return["invalid timezone ID: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.UG($CLJS.Jd,$CLJS.NI.tz.names())),$CLJS.EI],null)],null));
$CLJS.yF($CLJS.QI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zz,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mJ],null)],null)],null)],
null)]));$CLJS.pF($CLJS.QI,$CLJS.XI);$CLJS.wF($CLJS.dJ,$CLJS.H([$CLJS.it,$CLJS.VC]));$CLJS.X(TI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.pt,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Re(function(b){return $CLJS.rC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SC,$CLJS.rD],null))}],null)],null));
$CLJS.X(PI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pB,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TI],null)],null)],null)],null));
$CLJS.IG.g($CLJS.WI,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.h(null,1,[$CLJS.pt,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.WI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.h(null,1,[$CLJS.pt,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.II],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bH],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.h(null,1,[$CLJS.pt,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.Sv],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.MI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XG],null)],null)],null)],null)],null)],null));
$CLJS.LE.m(null,$CLJS.WI,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Sv)?$CLJS.E.g(a,$CLJS.ci)?$CLJS.rD:$CLJS.n($CLJS.oF($CLJS.bH,a))?$CLJS.SC:$CLJS.rD:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.nh($CLJS.zI,b))?$CLJS.SC:$CLJS.n($CLJS.nh($CLJS.AI,b))?$CLJS.SC:null:null;if($CLJS.n(a))return a;b=$CLJS.ME(b);b=$CLJS.vd(b)?$CLJS.eg.j($CLJS.bh,$CLJS.kf(function(c){return $CLJS.rC(c,$CLJS.ak)}),b):b;return $CLJS.vd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.A(b):b});$CLJS.X(iJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.Sv],null),$CLJS.yi],null));
$CLJS.yF($CLJS.bJ,$CLJS.H([$CLJS.it,$CLJS.rD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.si,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cH],null)],null)],null)],null)]));
$CLJS.wF($CLJS.Ey,$CLJS.H([$CLJS.it,$CLJS.RD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aH],null)]));$CLJS.X(RI,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.ss,$CLJS.ZG,$CLJS.Ci,$CLJS.rk,$CLJS.YI,$CLJS.sJ,$CLJS.tJ,$CLJS.Kj,$CLJS.Ii,$CLJS.wi,$CLJS.mj,$CLJS.WG],null));$CLJS.X(eJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,$CLJS.SI,$CLJS.aJ,$CLJS.fJ],null));
$CLJS.yF($CLJS.nJ,$CLJS.H([$CLJS.it,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.si,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eJ],null)],null)],
null)],null)]));