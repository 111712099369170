var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var Cz=function(){},Dz=function(a){return $CLJS.J.g($CLJS.ry,a).value},Gda=function(a){var b=Dz(a);return $CLJS.n(function(){var c=Dz($CLJS.Iy);return $CLJS.Jk.g?$CLJS.Jk.g(c,b):$CLJS.Jk.call(null,c,b)}())?$CLJS.nz:$CLJS.n(function(){var c=Dz($CLJS.Hy);return $CLJS.Jk.g?$CLJS.Jk.g(c,b):$CLJS.Jk.call(null,c,b)}())?$CLJS.xz:$CLJS.n(function(){var c=Dz($CLJS.Fy);return $CLJS.Jk.g?$CLJS.Jk.g(c,b):$CLJS.Jk.call(null,c,b)}())?$CLJS.qz:$CLJS.n(function(){var c=Dz($CLJS.zy);return $CLJS.Jk.g?
$CLJS.Jk.g(c,b):$CLJS.Jk.call(null,c,b)}())?$CLJS.wz:$CLJS.n(function(){var c=Dz($CLJS.Gy);return $CLJS.Jk.g?$CLJS.Jk.g(c,b):$CLJS.Jk.call(null,c,b)}())?$CLJS.yz:$CLJS.n(function(){var c=Dz($CLJS.Cy);return $CLJS.Jk.g?$CLJS.Jk.g(c,b):$CLJS.Jk.call(null,c,b)}())?$CLJS.tz:$CLJS.n(function(){var c=Dz($CLJS.wy);return $CLJS.Jk.g?$CLJS.Jk.g(c,b):$CLJS.Jk.call(null,c,b)}())?$CLJS.uz:$CLJS.vz},Ez=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},Fz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.be.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Bz,c))].join(""),$CLJS.H(["color:black"]))],null)},Gz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.be.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Bz,$CLJS.zz)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.Bz,
c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],null)},Hda=function(a){function b(c,d){return d>=c}a=Dz(a);if(b(Dz($CLJS.Iy),a))return"error";if(b(Dz($CLJS.Hy),a))return"warn";if(b(Dz($CLJS.Fy),a))return"info";b(Dz($CLJS.zy),a);return"log"},Hz=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.xy),e=$CLJS.J.g(c,$CLJS.Ht);b=$CLJS.J.g(c,$CLJS.Jy);e=Hda(e);e=$CLJS.Ca(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.Se.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=
["[",$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},Iz=function Iz(a,b){for(;;){if($CLJS.E.g($CLJS.mz,b))return Fz(a,", ",$CLJS.vz);if($CLJS.E.g($CLJS.oz,b))return Ez(a);if(b instanceof $CLJS.M)return Fz(a,b,$CLJS.qz);if(b instanceof $CLJS.r)return Fz(a,b,$CLJS.wz);if("string"===typeof b)return Fz(a,$CLJS.Mh.l($CLJS.H([b])),$CLJS.pz);if($CLJS.zf(b)){var d=Ez(function(){var f=a,k=$CLJS.Eb(b);return Iz.g?Iz.g(f,k):Iz.call(null,f,k)}()),e=$CLJS.Fb(b);
return Iz.g?Iz.g(d,e):Iz.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.Hg)return d=a,d=Fz(d,"{",$CLJS.Az),d=$CLJS.eb(Iz,d,$CLJS.ff($CLJS.mz,b)),Fz(d,"}",$CLJS.Az);if($CLJS.xd(b))return d=a,d=Fz(d,["#",$CLJS.p.h(function(){var f=$CLJS.ab(b),k=f.name;return $CLJS.td(k)?$CLJS.Mh.l($CLJS.H([f])):k}())," "].join(""),$CLJS.rz),d=Fz(d,"{",$CLJS.Az),d=$CLJS.eb(Iz,d,$CLJS.ff($CLJS.mz,b)),Fz(d,"}",$CLJS.Az);if($CLJS.vd(b))return d=a,d=Fz(d,"#{",$CLJS.Az),d=$CLJS.eb(Iz,d,$CLJS.ff($CLJS.oz,b)),Fz(d,
"}",$CLJS.Az);if($CLJS.zd(b))return d=a,d=Fz(d,"[",$CLJS.Az),d=$CLJS.eb(Iz,d,$CLJS.ff($CLJS.oz,b)),Fz(d,"]",$CLJS.Az);if(b instanceof $CLJS.Rf)d=Fz(a,"#queue ",$CLJS.rz),e=$CLJS.eg.g($CLJS.Cf,b),a=d,b=e;else{if($CLJS.Dd(b))return d=a,d=Fz(d,"(",$CLJS.rz),d=$CLJS.eb(Iz,d,$CLJS.ff($CLJS.oz,b)),Fz(d,")",$CLJS.rz);if(null!=b?b.I&16384||$CLJS.Bc===b.$i||(b.I?0:$CLJS.$a(Cz,b)):$CLJS.$a(Cz,b))d=Fz(a,"#atom ",$CLJS.rz),e=$CLJS.q(b),a=d,b=e;else if($CLJS.ei(b))d=Fz(a,"#uuid ",$CLJS.rz),e=$CLJS.p.h(b),a=d,
b=e;else if($CLJS.Xa(b))d=Fz(a,"#js ",$CLJS.rz),e=$CLJS.eb(function(f,k){return function(l,m){return $CLJS.R.j(l,$CLJS.zh.h(m),$CLJS.Ca(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Ta(b))d=Fz(a,"#js ",$CLJS.rz),e=$CLJS.eg.g($CLJS.Cf,b),a=d,b=e;else return Fz(a,$CLJS.Mh.l($CLJS.H([b])),$CLJS.sz)}}};$CLJS.Ida=Hz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Ht);var b=$CLJS.J.g(a,$CLJS.xy),c=$CLJS.J.g(a,$CLJS.Ti);$CLJS.J.g(a,$CLJS.Jy);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.Jda=Hz(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ht);a=$CLJS.J.g(b,$CLJS.xy);var d=$CLJS.J.g(b,$CLJS.Ti);$CLJS.J.g(b,$CLJS.Jy);b=Gda(c);d=Iz(Ez(Gz(Gz(Gz(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.Cf],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.ee(a,d)});
$CLJS.Kda=Hz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Ht);var b=$CLJS.J.g(a,$CLJS.xy),c=$CLJS.J.g(a,$CLJS.Ti);$CLJS.J.g(a,$CLJS.Jy);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Mh.l($CLJS.H([c]))],null)});