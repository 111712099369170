import { t } from "ttag";

import Button from "metabase/core/components/Button";
import ExternalLink from "metabase/core/components/ExternalLink";
import Tooltip from "metabase/core/components/Tooltip";
import * as MetabaseAnalytics from "metabase/lib/analytics";

import {
  Header,
  Paragraph,
  StyledCollapseSection,
} from "./EmbeddingLegalese.styled";

interface EmbeddingLegaleseProps {
  setting: {
    placeholder: string;
    is_env_setting: boolean;
  };
  onChange: (isEmbeddingEnabled: boolean) => void;
}

const EmbeddingLegalese = ({ setting, onChange }: EmbeddingLegaleseProps) => (
  <div className="text-measure">
    <Header>{t`Embedding`}</Header>
    <Paragraph>
      {t`Embed dashboards, questions, or the entire Metabase app into your application. Integrate with your server code to create a secure environment, limited to specific users or organizations.`}
    </Paragraph>
    <Tooltip
      tooltip={setting.placeholder}
      isEnabled={setting.is_env_setting}
      maxWidth={300}
    >
      <Button
        primary
        disabled={setting.is_env_setting}
        onClick={() => {
          MetabaseAnalytics.trackStructEvent(
            "Admin Embed Settings",
            "Embedding Enable Click",
          );
          onChange(true);
        }}
      >{t`Enable`}</Button>
    </Tooltip>
  </div>
);

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default EmbeddingLegalese;
