const SERVICE_MGS_TYPE = {
  START_TIPS: "SERVICE_MGS_TYPE_START_TIPS",
  STOP_TIPS: "SERVICE_MGS_TYPE_STOP_TIPS",
  START_AIM: "SERVICE_MGS_TYPE_START_AIM",
  STOP_AIM: "SERVICE_MGS_TYPE_STOP_AIM",
};

const CLIENT_MGS_TYPE = {
  NEW_HREF: "CLIENT_MGS_TYPE_NEW_HREF",
  CANCEL_TIPS: "CLIENT_MGS_TYPE_CANCEL_TIPS",
  SAVE_AIM: "CLIENT_MGS_TYPE_SAVE_AIM",
  CANCEL_AIM: "CLIENT_MGS_TYPE_CANCEL_AIM",
  KEYDOWN_ESC: "CLIENT_MGS_TYPE_KEYDOWN_ESC",
  CLICK: "CLIENT_MGS_TYPE_CLICK",
};

export { SERVICE_MGS_TYPE, CLIENT_MGS_TYPE };
