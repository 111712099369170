var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./clojure.string.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");
'use strict';var V$=function(a){return new $CLJS.ne(null,function(){return $CLJS.ee(a.o?a.o():a.call(null),V$(a))},null,null)},W$=function(a,b){return new $CLJS.ne(null,function(){var c=$CLJS.y(b);if(c){var d=$CLJS.A(c),e=a.h?a.h(d):a.call(null,d),f=$CLJS.ee(d,$CLJS.tu(function(k){return $CLJS.E.g(e,a.h?a.h(k):a.call(null,k))},$CLJS.B(c)));return $CLJS.ee(f,W$(a,new $CLJS.ne(null,function(){return $CLJS.bf($CLJS.D(f),c)},null,null)))}return null},null,null)},gxa=function(a,b){switch(a){case "minutes":return $CLJS.wV("Minute",
"Minutes",b);case "hours":return $CLJS.wV("Hour","Hours",b);case "days":return $CLJS.wV("Day","Days",b);case "weeks":return $CLJS.wV("Week","Weeks",b);case "months":return $CLJS.wV("Month","Months",b);case "quarters":return $CLJS.wV("Quarter","Quarters",b);case "years":return $CLJS.wV("Year","Years",b);default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},hxa=function(a,b,c){b=parseInt(b);c=gxa(c,b);a=new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.E.g(b,1)],null);if($CLJS.E.g(new $CLJS.P(null,
2,5,$CLJS.Q,["past",!0],null),a))return $CLJS.hE("Previous {0}",$CLJS.H([c]));if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,["past",!1],null),a))return $CLJS.hE("Previous {0} {1}",$CLJS.H([b,c]));if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,["next",!0],null),a))return $CLJS.hE("Next {0}",$CLJS.H([c]));if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,["next",!1],null),a))return $CLJS.hE("Next {0} {1}",$CLJS.H([b,c]));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));},jxa=function(a){return $CLJS.CA(a,
ixa,function(b){return["\\",$CLJS.p.h(b)].join("")})},X$=function(a,b,c,d,e){this.tag=a;this.source=b;this.S=c;this.G=d;this.J=e;this.C=2230716170;this.I=139264},lxa=function(a){var b=$CLJS.Pt(a,kxa),c=$CLJS.hf.g($CLJS.A,$CLJS.RW(Y$,a)),d=function(){var e=$CLJS.D(b),f=$CLJS.D(c);return e>f?e:f}();a=$CLJS.gf.g(b,$CLJS.af(d-$CLJS.D(b),V$($CLJS.Ue(""))));d=$CLJS.gf.g(c,$CLJS.af(d-$CLJS.D(c),V$($CLJS.Ue(""))));a=$CLJS.ef.g(a,d);return $CLJS.hf.g(function(e){var f=$CLJS.nh(Y$,e);return $CLJS.n(f)?($CLJS.I(f,
0,null),f=$CLJS.I(f,1,null),new X$(f,e,null,null,null)):e},a)},mxa=function(a){return $CLJS.jf(function(b){return"string"===typeof $CLJS.A(b)?new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rV(b)],null):b},$CLJS.H([W$(function(b){return"string"===typeof b||$CLJS.Va($CLJS.Hj.h(b))},a)]))},nxa=function(a,b,c,d){return $CLJS.hf.g(function(e){if(e instanceof X$){var f=$CLJS.R.j;var k=$CLJS.Lj.h(e),l=$CLJS.J.g(a,k);k=$CLJS.Hj.h(l);l=$CLJS.qj.h(l);if($CLJS.n(k))try{var m=Z$.j(l,k,b);var t=$CLJS.n(c)?jxa(m):m}catch(u){if(u instanceof
Error)t=Z$.j($CLJS.ci,k,b);else throw u;}else t=null;e=f.call($CLJS.R,e,$CLJS.Hj,t)}return e},d)},qxa=function(a){a=$CLJS.rV($CLJS.hf.g(function(b){return b instanceof X$?b:$CLJS.CA(b,oxa,"")},a));return $CLJS.CA(a,pxa,$CLJS.hd)},rxa=function(a){return $CLJS.AA($CLJS.A($CLJS.yY(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.tN],null),new $CLJS.P(null,1,5,$CLJS.Q,[a],null))),$CLJS.zh)};var Z$=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.lj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.shared.parameters.parameters","formatted-value"),function(f){return $CLJS.zh.h(f)},e,a,b,c,d)}();Z$.m(null,$CLJS.WO,function(a,b,c){return(0,$CLJS.NI)(b).locale(c).format("MMMM D, YYYY")});
Z$.m(null,$CLJS.yN,function(a,b,c){a=(0,$CLJS.NI)(b,"YYYY-MM").locale(c);return $CLJS.n(a.isValid())?a.format("MMMM, YYYY"):""});Z$.m(null,$CLJS.sO,function(a,b,c){a=(0,$CLJS.NI)(b,"[Q]Q-YYYY").locale(c);return $CLJS.n(a.isValid())?a.format("[Q]Q, YYYY"):""});Z$.m(null,$CLJS.bN,function(a,b,c){b=$CLJS.Pt(b,/~/);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.n($CLJS.n(a)?b:a)?[$CLJS.p.h(Z$.j($CLJS.WO,a,c))," - ",$CLJS.p.h(Z$.j($CLJS.WO,b,c))].join(""):""});
Z$.m(null,$CLJS.tO,function(a,b){function c(d,e){return $CLJS.B($CLJS.oh(d,e))}if(c(/^today$/,b)||c(/^thisday$/,b))return $CLJS.JE("Today");if(c(/^thisweek$/,b))return $CLJS.JE("This Week");if(c(/^thismonth$/,b))return $CLJS.JE("This Month");if(c(/^thisquarter$/,b))return $CLJS.JE("This Quarter");if(c(/^thisyear$/,b))return $CLJS.JE("This Year");if(c(/^past1days$/,b))return $CLJS.JE("Yesterday");if(c(/^next1days$/,b))return $CLJS.JE("Tomorrow");if(a=c(/^(past|next)([0-9]+)([a-z]+)~?$/,b))return $CLJS.Se.g(hxa,
a);throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));});Z$.m(null,$CLJS.aS,function(a,b,c){function d(e,f){return $CLJS.hd($CLJS.oh(e,f))}a=d(/^(this[a-z]+)$/,b);if($CLJS.n(a))return Z$.j($CLJS.tO,a,c);a=d(/^~?([0-9-T:]+)~?$/,b);if($CLJS.n(a))return Z$.j($CLJS.WO,a,c);a=d(/^([0-9-T:]+~[0-9-T:]+)$/,b);return $CLJS.n(a)?Z$.j($CLJS.bN,a,c):Z$.j($CLJS.tO,b,c)});
Z$.m(null,$CLJS.ci,function(a,b){$CLJS.wd(b)?(a=$CLJS.D(b),b=$CLJS.n($CLJS.E.g?$CLJS.E.g(1,a):$CLJS.E.call(null,1,a))?$CLJS.p.h($CLJS.A(b)):$CLJS.n($CLJS.E.g?$CLJS.E.g(2,a):$CLJS.E.call(null,2,a))?$CLJS.hE("{0} and {1}",$CLJS.H([$CLJS.A(b),$CLJS.hd(b)])):$CLJS.hE("{0}, {1}, and {2}",$CLJS.H([$CLJS.Bt(", ",$CLJS.uu(2,b)),$CLJS.F(b,$CLJS.D(b)-2),$CLJS.id(b)]))):b=$CLJS.p.h(b);return b});var ixa=/[\\\/*_`'\[\](){}<>#+-.!$@%^&=|\?~]/,Y$=/\{\{\s*([A-Za-z0-9_\.]+?)\s*\}\}/,kxa=/\{\{\s*[A-Za-z0-9_\.]+?\s*\}\}/;
$CLJS.g=X$.prototype;$CLJS.g.toString=function(){var a=$CLJS.Hj.h(this);return $CLJS.n(a)?a:this.source};$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "tag":return this.tag;case "source":return this.source;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#metabase.shared.parameters.parameters.TextParam{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,this.tag],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zz,this.source],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.zt(this,2,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,$CLJS.Zz],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};
$CLJS.g.ha=function(){return 2+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-884441273^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.tag,b.tag)&&$CLJS.E.g(this.source,b.source)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Id(new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Zz,null,$CLJS.Lj,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new X$(this.tag,this.source,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "tag":case "source":return!0;default:return $CLJS.Id(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Lj,b):$CLJS.he.call(null,$CLJS.Lj,b))?new X$(c,this.source,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Zz,b):$CLJS.he.call(null,$CLJS.Zz,b))?new X$(this.tag,c,this.S,this.G,null):new X$(this.tag,this.source,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.Yf($CLJS.Lj,this.tag),new $CLJS.Yf($CLJS.Zz,this.source)],null),this.G))};
$CLJS.g.P=function(a,b){return new X$(this.tag,this.source,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.eb($CLJS.pb,this,b)};var oxa=/\[\[.+\]\]/,pxa=/\[\[(.+?)\]\]/,$$=function $$(a){switch(arguments.length){case 2:return $$.g(arguments[0],arguments[1]);case 4:return $$.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.za("metabase.shared.parameters.parameters.substitute_tags",$$);$$.g=function(a,b){return $$.v(a,b,"en",!0)};$$.v=function(a,b,c,d){return $CLJS.n(a)?(b=$CLJS.Mz(b),b=$CLJS.xt(b,rxa),qxa(mxa(nxa(b,c,d,lxa(a))))):null};$$.A=4;module.exports={tag_names:function(a){a=$CLJS.fh($CLJS.hf.g($CLJS.hd,$CLJS.RW(Y$,$CLJS.n(a)?a:"")));return $CLJS.il(a)},substitute_tags:$$};