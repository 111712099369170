var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var sZ,Rma,Sma,Tma,Uma,Vma,yZ,Wma,Xma,Yma,BZ,Zma,$ma,ana,GZ,HZ,bna,cna,dna,QZ,ena,fna,gna,TZ,hna,ina,jna,kna;sZ=function(){};$CLJS.tZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.$a(sZ,a):$CLJS.$a(sZ,a)};Rma=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.uZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Sma=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Tma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.vZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.wZ=new $CLJS.M(null,"database-id","database-id",1883826326);Uma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.xZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Vma=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);yZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Wma=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Xma=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Yma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.AZ=new $CLJS.M(null,"dataset","dataset",1159262238);BZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Zma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.CZ=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.DZ=new $CLJS.M(null,"definition","definition",-1198729982);$ma=new $CLJS.M(null,"state","state",-1988618099);
$CLJS.EZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);ana=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.FZ=new $CLJS.M("source","joins","source/joins",1225821486);GZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);HZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);
$CLJS.IZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);bna=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.KZ=new $CLJS.M(null,"selected?","selected?",-742502788);cna=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);dna=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.LZ=new $CLJS.M(null,"table-name","table-name",-2117866341);
$CLJS.MZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.NZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.OZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.PZ=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);QZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);ena=new $CLJS.M(null,"details","details",1956795411);
$CLJS.RZ=new $CLJS.M("source","fields","source/fields",-649667981);fna=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.SZ=new $CLJS.M("source","native","source/native",-1444604147);gna=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);TZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);
$CLJS.UZ=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);hna=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);ina=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);jna=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);kna=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.VZ=new $CLJS.M(null,"source-alias","source-alias",1652088724);
$CLJS.WZ=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(GZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ss,$CLJS.MZ,$CLJS.SZ,$CLJS.vZ,$CLJS.xZ,$CLJS.RZ,$CLJS.hL,$CLJS.bL,$CLJS.FZ,$CLJS.CZ,$CLJS.EZ],null));$CLJS.X(QZ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,kna],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,$CLJS.VK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WK,$CLJS.fL],null)],null));
$CLJS.X(yZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,hna],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,$CLJS.VK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,$CLJS.li],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Vma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,$CLJS.li],null)],null)],null));
$CLJS.X($CLJS.zZ,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.pt,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.NK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.dk],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,$CLJS.AE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cj,new $CLJS.h(null,1,[$CLJS.Js,!0],null),$CLJS.fL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mE,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.us,$CLJS.dk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hi,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.AE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZK,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.fL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JZ,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.fL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VZ,
new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.rE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qE,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.rE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PK,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UZ,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.XK],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uZ,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.rE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IZ,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.h(null,2,[$CLJS.fo,1,$CLJS.ok,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KZ,new $CLJS.h(null,1,[$CLJS.Js,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,
[bna,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[jna,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yZ],null)],null)],null)],null));
$CLJS.X(TZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LZ,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Zma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[Yma,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,$CLJS.AE],null)],null)],null)],null)],null)],null));
$CLJS.X(BZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NZ,$CLJS.Gs],null),new $CLJS.P(null,2,5,$CLJS.Q,[$ma,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LZ,$CLJS.rE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DZ,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MK,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,
$CLJS.rE],null)],null)],null));
$CLJS.X(fna,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.pt,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.gL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,$CLJS.XK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wZ,$CLJS.YK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.h(null,1,[$CLJS.Js,!0],null),$CLJS.nk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PZ,
new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,$CLJS.nk],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AZ,new $CLJS.h(null,1,[$CLJS.Js,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$K,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.SK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Tma,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,BZ],null)],null)],null)],null));
$CLJS.X(cna,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.pt,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.RK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,$CLJS.QK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$K,$CLJS.SK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.nk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Jt,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.rE],null)],null)],null));
$CLJS.X(dna,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.pt,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.TK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,$CLJS.OK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$K,$CLJS.SK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.nk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Jt,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.rE],null)],null)],null));
$CLJS.X(gna,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.pt,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.UK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,$CLJS.SK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mE,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.rE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wl,new $CLJS.h(null,
1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.rE],null)],null)],null));
$CLJS.X(Sma,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.pt,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.dL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,$CLJS.YK],null),new $CLJS.P(null,3,5,$CLJS.Q,[Xma,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.nk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[ena,new $CLJS.h(null,1,[$CLJS.Js,!0],null),$CLJS.nk],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.WZ,new $CLJS.h(null,1,[$CLJS.Js,!0],null),$CLJS.aj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eA,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.aj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[ana,new $CLJS.h(null,1,[$CLJS.Js,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aL,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.nk],null)],null)],null));
$CLJS.X(HZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.pt,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.tZ},Uma,$CLJS.Ig([$CLJS.$i,$CLJS.T,$CLJS.uk,$CLJS.vi,$CLJS.Wi,$CLJS.rj,$CLJS.Mk,$CLJS.ui,$CLJS.pk,$CLJS.tk],[Rma,ina,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Yq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.tZ)?$CLJS.tZ.H:null]))],null));
$CLJS.X(Wma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.pt,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HZ],null)],null)],null)],null));