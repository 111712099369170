export default {
  aim: `
<div id="custom-intro-wrapper" class="custom-intro-wrapper">
  <div class="custom-intro-text">确认选取该元素?</div>
  <div class="custom-intro-button-wrapper">
    <button id="intro-confirm" class="custom-intro-button custom-intro-aim-save-btn">确认</button>
    <button id="intro-cancel" class="custom-intro-button custom-intro-aim-cancel-btn">再想想</button>
  </div>
</div>
    `,
  show: `
<div id="custom-intro-wrapper" class="custom-intro-wrapper">
  <div class="custom-intro-text">$description$</div>
  <div class="custom-intro-button-wrapper">
    <button id="intro-close" class="custom-intro-button custom-intro-show-close-btn">关闭</button>
    <button id="intro-continue" class="custom-intro-button custom-intro-show-continue-btn">继续</button>
  </div>
</div>
  `,
};
