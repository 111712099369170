var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var wH,xH,zH,AH,Pfa,Qfa,Rfa,BH,yH;$CLJS.uH=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.vH=function(a,b){return $CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
wH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.rC(b,$CLJS.SC)?$CLJS.fH:$CLJS.rC(b,$CLJS.RD)?$CLJS.aH:$CLJS.rC(b,$CLJS.rD)?$CLJS.cH:null;return $CLJS.n(b)?$CLJS.oF(b,a):!0};
xH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.uH(function(d){return $CLJS.rC($CLJS.ME(d),$CLJS.cD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.bl.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.ME($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(wH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
zH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.h(null,1,[$CLJS.pt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.rt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Hj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(xH(b))].join("")}],null),$CLJS.Te(xH)],null)],null)};
AH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.h(null,1,[$CLJS.pt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.fo,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)],null)],null)};
Pfa=function(a){return $CLJS.Qd($CLJS.rF,$CLJS.hf.g(function(b){var c=$CLJS.ME(b),d=$CLJS.rC(c,$CLJS.YE);b=d?$CLJS.oF($CLJS.lG,b):d;return $CLJS.n(b)?$CLJS.Tj:c},a))};Qfa=function(a){a=$CLJS.vH(function(b){return!$CLJS.rC(b,$CLJS.cD)},$CLJS.hf.g($CLJS.ME,a));return $CLJS.rC(a,$CLJS.YE)?$CLJS.ak:a};
Rfa=function(a,b){return $CLJS.n($CLJS.Re(function(c){return $CLJS.rC($CLJS.ME(c),$CLJS.cD)},b))?Qfa(b):$CLJS.E.g(a,$CLJS.it)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Qe(function(c){return $CLJS.rC($CLJS.ME(c),$CLJS.SC)},b)||$CLJS.Qe(function(c){return $CLJS.rC($CLJS.ME(c),$CLJS.rD)},b))?$CLJS.cD:Pfa(b)};BH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.CH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);yH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,yH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.pt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.ME(a);return $CLJS.Qe(function(d){return wH(d,c)},b)}],null)],null));
$CLJS.X(BH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,new $CLJS.h(null,1,[$CLJS.fo,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)],null));
var Sfa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.h(null,1,[$CLJS.pt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.it],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)],
null);$CLJS.IG.g($CLJS.bs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,zH($CLJS.bs),AH($CLJS.bs)],null));$CLJS.IG.g($CLJS.it,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vs,zH($CLJS.it),Sfa,AH($CLJS.it)],null));$CLJS.yF($CLJS.cs,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,BH],null)]));$CLJS.yF($CLJS.DG,$CLJS.H([$CLJS.it,$CLJS.OD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,BH],null)]));
for(var DH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,$CLJS.it,$CLJS.cs],null)),EH=null,FH=0,GH=0;;)if(GH<FH){var Tfa=EH.X(null,GH);$CLJS.pF(Tfa,$CLJS.CH);GH+=1}else{var HH=$CLJS.y(DH);if(HH){var IH=HH;if($CLJS.Ad(IH)){var JH=$CLJS.lc(IH),Ufa=$CLJS.mc(IH),Vfa=JH,Wfa=$CLJS.D(JH);DH=Ufa;EH=Vfa;FH=Wfa}else{var Xfa=$CLJS.A(IH);$CLJS.pF(Xfa,$CLJS.CH);DH=$CLJS.B(IH);EH=null;FH=0}GH=0}else break}
$CLJS.LE.m(null,$CLJS.CH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Rfa(a,b)});$CLJS.wF($CLJS.IF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));$CLJS.pF($CLJS.IF,$CLJS.bF);
for(var KH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FG,$CLJS.zF,$CLJS.VF],null)),LH=null,MH=0,NH=0;;)if(NH<MH){var Yfa=LH.X(null,NH);$CLJS.wF(Yfa,$CLJS.H([$CLJS.it,$CLJS.OD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));NH+=1}else{var OH=$CLJS.y(KH);if(OH){var PH=OH;if($CLJS.Ad(PH)){var QH=$CLJS.lc(PH),Zfa=$CLJS.mc(PH),$fa=QH,aga=$CLJS.D(QH);KH=Zfa;LH=$fa;MH=aga}else{var bga=$CLJS.A(PH);$CLJS.wF(bga,$CLJS.H([$CLJS.it,$CLJS.OD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));KH=$CLJS.B(PH);LH=null;MH=0}NH=0}else break}
for(var RH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DF,$CLJS.fG,$CLJS.mG],null)),SH=null,TH=0,UH=0;;)if(UH<TH){var cga=SH.X(null,UH);$CLJS.wF(cga,$CLJS.H([$CLJS.it,$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));UH+=1}else{var VH=$CLJS.y(RH);if(VH){var WH=VH;if($CLJS.Ad(WH)){var XH=$CLJS.lc(WH),dga=$CLJS.mc(WH),ega=XH,fga=$CLJS.D(XH);RH=dga;SH=ega;TH=fga}else{var gga=$CLJS.A(WH);$CLJS.wF(gga,$CLJS.H([$CLJS.it,$CLJS.Aj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));RH=$CLJS.B(WH);SH=null;TH=0}UH=0}else break}$CLJS.wF($CLJS.FF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));
$CLJS.LE.m(null,$CLJS.FF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.rC($CLJS.ME(b),$CLJS.Aj)&&$CLJS.rC($CLJS.ME(a),$CLJS.Aj)?$CLJS.Aj:$CLJS.OD});