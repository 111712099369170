import {t} from "ttag";
import {isDeprecatedEngine} from "metabase/lib/engine";
import {getSetting} from "metabase/selectors/settings";
import type Database from "metabase-lib/metadata/Database";
import type { State } from "metabase-types/store";

interface Props {
  databases?: Database[];
}

export const hasSlackBot = (state: State): boolean => {
  return getSetting(state, "slack-token") != null;
};

export const isNoticeEnabled = (state: State): boolean => {
  return state.admin.app.isNoticeEnabled;
};

export const hasDeprecatedDatabase = (state: State, props: Props): boolean => {
  return (
    props.databases?.some(d => !d.is_sample && isDeprecatedEngine(d.engine)) ??
    false
  );
};

export const getAdminPaths = (state: State) => {
  if (state.currentUser?.is_superuser) {
    return state.admin?.app?.paths ?? [];
  } else {
    return [
      {
        name: t`Table Metadata`,
        path: "/admin/datamodel",
        key: "data-model",
      }
    ]
  }
};

export const canAccessAdmin = (state: State): boolean => {
  return getAdminPaths(state).length > 0;
};

export const canAccessPath = (
  state: State,
  { key }: { key: string },
): boolean => {
  return state.admin.app.paths?.find(path => path.key === key) != null;
};
