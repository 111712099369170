import introJs from "intro.js";

import "intro.js/introjs.css";
import "./briefcase.core.css";
import { fillValue, getXPath, parseMgs } from "./briefcase.core.common";
import htmlCode from "./briefcase.core.html.code.ZH";
import { SERVICE_MGS_TYPE, CLIENT_MGS_TYPE } from "./briefcase.core.mgs";

let tips = [];
let relative = 0;
let intervalId = undefined;
const intro = introJs().setOptions({
  exitOnOverlayClick: false,
  showBullets: false,
  showButtons: false,
});

const enableBriefcaseClient = () => {
  sendHrefValuePeriodically();
  window.addEventListener("message", handleServiceMgs);
  window.addEventListener(SERVICE_MGS_TYPE.START_TIPS, handleStartTips);
  window.addEventListener(SERVICE_MGS_TYPE.STOP_TIPS, handleStopTips);
  window.addEventListener(SERVICE_MGS_TYPE.START_AIM, handleStartAim);
  window.addEventListener(SERVICE_MGS_TYPE.STOP_AIM, handleStopAim);
  window.addEventListener("keydown", handleKeyDown);
  window.addEventListener("click", handleWindowClick);
};

const disableBriefcaseClient = () => {
  clearInterval(intervalId);
  window.removeEventListener("message", handleServiceMgs);
  window.removeEventListener(SERVICE_MGS_TYPE.START_TIPS, handleStartTips);
  window.removeEventListener(SERVICE_MGS_TYPE.STOP_TIPS, handleStopTips);
  window.removeEventListener(SERVICE_MGS_TYPE.START_AIM, handleStartAim);
  window.removeEventListener(SERVICE_MGS_TYPE.STOP_AIM, handleStopAim);
  window.removeEventListener("keydown", handleKeyDown);
  window.removeEventListener("click", handleWindowClick);
};

const handleServiceMgs = event => {
  const mgs = parseMgs(event.data);
  if (mgs) {
    const customEvent = new CustomEvent(mgs?.type, { detail: mgs.data });
    window.dispatchEvent(customEvent);
  }
};

//每隔2s向父页面推送一个href的值
const sendHrefValuePeriodically = () => {
  intervalId = setInterval(() => {
    window.parent.postMessage(
      JSON.stringify({
        type: CLIENT_MGS_TYPE.NEW_HREF,
        data: { href: window.location.href },
      }),
      "*",
    );
  }, 2000);
};

const handleStartTips = event => {
  exitIntro();
  tips = event.detail.tips;
  relative = event.detail.relative || 0;
  showCurTip();
};

const handleStopTips = () => {
  exitIntro();
};

const handleStartAim = () => {
  exitIntro();
  window.addEventListener("click", handleClickedAim, { capture: true });
};

const handleStopAim = () => {
  exitIntro();
};

const handleKeyDown = event => {
  if (event.keyCode === 27) {
    const message = {
      type: CLIENT_MGS_TYPE.KEYDOWN_ESC,
    };
    window.parent.postMessage(JSON.stringify(message), "*");
    exitIntro();
  }
};

const handleWindowClick = () => {
  const message = {
    type: CLIENT_MGS_TYPE.CLICK,
  };
  window.parent.postMessage(JSON.stringify(message), "*");
};

const handleClickedAim = event => {
  event.preventDefault();
  event.stopPropagation();
  exitIntro();
  intro
    .setOption("steps", [
      {
        element: event.target,
      },
    ])
    .start()
    .then(_ => {
      preIntro(htmlCode.aim);
      addAimSaveEvent(event);
      addAimCancelEvent();
    });
};

const showCurTip = _ => {
  if (relative >= 0 && relative < tips.length) {
    const tip = tips[relative];
    let showHtmlCode = htmlCode.show;
    showHtmlCode = fillValue(showHtmlCode, {
      description: tip.description,
    });
    intro.exit().then(_ => {
      intro
        .setOption("steps", [
          {
            intro: tip.description,
            element: document.evaluate(
              tip.xPath,
              document,
              null,
              XPathResult.FIRST_ORDERED_NODE_TYPE,
              null,
            ).singleNodeValue,
          },
        ])
        .start()
        .then(_ => {
          preIntro(showHtmlCode);
          addShowCloseEvent();
          addContinueEvent();
        });
    });
  }
};

const preIntro = htmlCode => {
  const tooltips = document.getElementsByClassName("introjs-tooltip");
  for (const tip of tooltips) {
    tip.innerHTML = htmlCode;
  }
};

let time = new Date().getTime();
const exitIntro = () => {
  if (new Date().getTime() - time < 500) {
    time = new Date().getTime();
    return;
  }
  time = new Date().getTime();
  intro?.exit();
  window.removeEventListener("click", handleClickedAim, { capture: true });
  tips = [];
  relative = 0;
};

const addAimSaveEvent = event => {
  const element = document.getElementById("intro-confirm");
  if (!element) {
    return;
  }
  element.onclick = () => {
    const xPath = getXPath(event.target);
    const message = {
      type: CLIENT_MGS_TYPE.SAVE_AIM,
      data: {
        xPath: xPath,
        rootPath: window.location.pathname,
      },
    };
    window.parent.postMessage(JSON.stringify(message), "*");
    exitIntro();
  };
};

const addAimCancelEvent = () => {
  const element = document.getElementById("intro-cancel");
  if (!element) {
    return;
  }
  element.onclick = emitAimCancel;
};

const emitAimCancel = () => {
  const message = {
    type: CLIENT_MGS_TYPE.CANCEL_AIM,
  };
  window.parent.postMessage(JSON.stringify(message), "*");
  exitIntro();
};

const addShowCloseEvent = () => {
  const element = document.getElementById("intro-close");
  if (!element) {
    return;
  }
  element.onclick = emitCloseTip;
};

const emitCloseTip = () => {
  const message = {
    type: CLIENT_MGS_TYPE.CANCEL_TIPS,
  };
  window.parent.postMessage(JSON.stringify(message), "*");
  exitIntro();
};

const addContinueEvent = () => {
  const element = document.getElementById("intro-continue");
  if (!element) {
    return;
  }
  if (relative >= tips.length - 1) {
    element.remove();
    return;
  }
  element.onclick = () => {
    const temp = relative + 1;
    if (temp < tips.length) {
      relative = temp;
    }
    showCurTip();
  };
};

export { enableBriefcaseClient, disableBriefcaseClient };
