var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var cfa,oE,sE,DE;
$CLJS.fE=function(a,b){return function(){function c(l,m,t){return $CLJS.Ed(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.Ed(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.Ed(l)}var f=null,k=function(){function l(t,u,v,x){var z=null;if(3<arguments.length){z=0;for(var C=Array(arguments.length-3);z<C.length;)C[z]=arguments[z+3],++z;z=new $CLJS.w(C,0,null)}return m.call(this,t,u,v,z)}function m(t,u,v,x){return $CLJS.Ed(function(){var z=f.j(t,u,v);return $CLJS.n(z)?$CLJS.Qe(function(C){var G=a.h?a.h(C):a.call(null,C);return $CLJS.n(G)?
b.h?b.h(C):b.call(null,C):G},x):z}())}l.A=3;l.B=function(t){var u=$CLJS.A(t);t=$CLJS.B(t);var v=$CLJS.A(t);t=$CLJS.B(t);var x=$CLJS.A(t);t=$CLJS.Lc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.w(x,0,null)}return k.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.B=k.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};$CLJS.gE=function(a){return $CLJS.Ok.g($CLJS.hf.h(a),$CLJS.qC)};$CLJS.hE=function(a,b){a=$CLJS.Pt($CLJS.CA(a,/''/,"'"),/\{\d+\}/);return $CLJS.Se.j($CLJS.vt.t,$CLJS.il(a),$CLJS.il(b))};$CLJS.iE=new $CLJS.M("lib","metadata","lib/metadata",1798917220);$CLJS.jE=new $CLJS.M("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);
$CLJS.kE=new $CLJS.M(null,"num-bins","num-bins",847672055);$CLJS.lE=new $CLJS.M(null,"mbql","mbql",69346710);$CLJS.mE=new $CLJS.M(null,"display-name","display-name",694513143);cfa=new $CLJS.M("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.nE=new $CLJS.M("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);
oE=new $CLJS.M("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.pE=new $CLJS.M("lib","external-op","lib/external-op",-1470923877);$CLJS.qE=new $CLJS.M("lib","expression-name","lib/expression-name",-1799326590);$CLJS.rE=new $CLJS.M("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);sE=new $CLJS.M("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);
$CLJS.tE=new $CLJS.M(null,"database-type","database-type",-426840562);$CLJS.uE=new $CLJS.M(null,"operator","operator",-1860875338);$CLJS.vE=new $CLJS.M("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.wE=new $CLJS.M("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);
$CLJS.xE=new $CLJS.M("metabase.lib.schema.common","positive-number","metabase.lib.schema.common/positive-number",2061626826);$CLJS.yE=new $CLJS.M("lib","uuid","lib/uuid",-2145250720);$CLJS.zE=new $CLJS.M(null,"x","x",2099068185);$CLJS.AE=new $CLJS.M("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);$CLJS.BE=new $CLJS.M(null,"bin-width","bin-width",1377922579);$CLJS.CE=new $CLJS.M(null,"strategy","strategy",-1471631918);
DE=new $CLJS.M("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.X($CLJS.rE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.h(null,1,[$CLJS.fo,1],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.pt,"non-blank string"],null),$CLJS.Te($CLJS.DA)],null)],null));$CLJS.X($CLJS.vE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.fo,0],null)],null));$CLJS.X($CLJS.jE,$CLJS.Wl);
$CLJS.X($CLJS.xE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,1,[$CLJS.pt,"positive number"],null),$CLJS.fE($CLJS.Al,$CLJS.cm)],null));$CLJS.X(DE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dk,new $CLJS.h(null,2,[$CLJS.fo,36,$CLJS.ok,36],null)],null));
$CLJS.X(sE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,2,[$CLJS.pt,"valid semantic type",$CLJS.rt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Hj);return["Not a valid semantic type: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.rC(a,$CLJS.wj)}],null));
$CLJS.X(oE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,2,[$CLJS.pt,"valid relation type",$CLJS.rt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Hj);return["Not a valid relation type: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.rC(a,$CLJS.ni)}],null));$CLJS.X($CLJS.nE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oE],null)],null));
$CLJS.X($CLJS.AE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ui,new $CLJS.h(null,2,[$CLJS.pt,"valid base type",$CLJS.rt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Hj);return["Not a valid base type: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.rC(a,$CLJS.Ej)}],null));
$CLJS.X($CLJS.wE,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yE,DE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pB,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.AE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hi,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.AE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zj,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,
$CLJS.nE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tE,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.rE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.rE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mE,new $CLJS.h(null,1,[$CLJS.Js,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.us,$CLJS.rE],null)],null)],null));
$CLJS.X(cfa,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.pE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.dk,$CLJS.aj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,$CLJS.li],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jj,new $CLJS.h(null,1,[$CLJS.Js,!0],null),$CLJS.wE],null)],null));